import React, { useEffect, useRef, useState } from "react";
import styles from "./whoami.module.scss";
import cx from "classnames";
import { Link } from "gatsby";
import V2Entry from "../../v2-entry";
import AFGCLogo from "../../../commons/afgc-logo/AFGCLogo";
import { RouteLinks } from "../../../../utils/route-links";
import LogoIcon from "../../../../images/icons/v2/logo.inline.svg";
import MenuOverlay from "../../menu-overlay/MenuOverlay";
import { MenuOverlayOptions } from "../../../../enums/menu-overlay-options";
import MenuIcon from "../../../../images/icons/v2/menu-burger.inline.svg";
import DarkModeIcon from "../../../../images/icons/dark.inline.svg";
import SVGContainer from "../../../commons/svg-container/SVGContainer";
import WhoAmIComponent from "../../../commons/whoami";

const WhoAmI = () => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [headerHeight, setHeaderHeight] = useState<number>();

  const headerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    setTimeout(() => {
      const headerHeight = headerRef.current!.scrollHeight;
      setHeaderHeight(headerHeight);
    }, 1);
  }, []);

  return (
    <V2Entry>
      <WhoAmIComponent headerHeight={headerHeight}>
        <header ref={headerRef} className={styles.header}>
          <div className={styles.header__content}>
            <div className={cx(styles.logo, styles.padding)}>
              <AFGCLogo dataTestE2E="logo">
                <LogoIcon />
              </AFGCLogo>
              <p className={styles.logo__professional}>A Few Good Clicks </p>
            </div>
            <div className={cx(styles.navigation)}>
              <ul>
                <li className={cx(styles.padding, styles.navigation__selected)}>
                  <Link
                    to={RouteLinks.whoAmI}
                    className="link"
                    data-test-e2e="whoami"
                  >
                    Who Am I
                  </Link>
                </li>
                <li className={cx(styles.padding)}>
                  <Link
                    to={RouteLinks.contact}
                    className="link"
                    data-test-e2e="contact"
                  >
                    Contact Me
                  </Link>
                </li>
              </ul>
              {/* <SVGContainer
                iconSize={IconSizes.IC__30}
                className={styles.darkMode}
              >
                <DarkModeIcon />
              </SVGContainer> */}
              <div className={styles.divider}>&nbsp;</div>
              <div
                className={cx(styles.menu, styles.padding)}
                onClick={() => setIsPopUpOpen(true)}
                data-test-e2e="menu"
              >
                <p>Menu</p>
                <SVGContainer>
                  <MenuIcon />
                </SVGContainer>
              </div>
            </div>
          </div>
        </header>
      </WhoAmIComponent>
      <MenuOverlay
        closePopUp={() => setIsPopUpOpen(false)}
        isOpen={isPopUpOpen}
        selected={MenuOverlayOptions.WHO_AM_I}
      />
    </V2Entry>
  );
};

export default WhoAmI;
