import React, { useEffect, useState } from "react";
import { S3Directory } from "../../../enums/s3-directory.enum";
import { graphql, useStaticQuery } from "gatsby";
import styles from "./whoami.module.scss";
import SEO from "../seo";
import { RouteLinks } from "../../../utils/route-links";
import cx from "classnames";
import AsteriskIcon from "../../../images/icons/asterisk.inline.svg";
import RotatingTextAnimation from "../rotating-text-animation/RotatingTextAnimation";
import CurveIcon from "../../../images/icons/curve-icon.svg";
import LinkButton from "../../v2/link-button/LinkButton";
import { useMediaQuery } from "react-responsive";
import { whoAmIMobile } from "../../../utils/utils";

interface Props {
  children: React.ReactNode;
  headerHeight: number | undefined;
}

const rotatingWords = [
  "Professional",
  "Creative",
  "Experienced",
  "Passionate",
  "Collaborative",
  "Adventurous",
];

const query = graphql`
  {
    seo: markdownRemark(
      fields: { category: { eq: "seo" }, relativePath: { eq: "seo__whoAmI" } }
    ) {
      frontmatter {
        metaTitle
        metaDescription
        metaKeywordList
        metaImageName
      }
    }

    metadata: site {
      siteMetadata {
        cloudfront_base
      }
    }
  }
`;

const WhoAmI: React.FC<Props> = (props: Props) => {
  const [currentWord, setCurrentWord] = useState(0);
  const rotatingTextResponsiveness = useMediaQuery({ query: whoAmIMobile });

  const queryResult = useStaticQuery(query);

  const cloudfrontBaseUrl: string =
    queryResult.metadata.siteMetadata.cloudfront_base;

  useEffect(() => {
    //Image Preloading
    rotatingWords.forEach(el => {
      const img = new Image();
      img.src = `${cloudfrontBaseUrl}/${
        S3Directory.WHOAMI_PAGE_IMAGES
      }/${el.toLowerCase()}.jpg`;
    });
  }, []);

  const {
    metaTitle,
    metaDescription,
    metaKeywordList,
    metaImageName,
  } = queryResult.seo.frontmatter;

  const imageUrl = `${cloudfrontBaseUrl}/${
    S3Directory.WHOAMI_PAGE_IMAGES
  }/${rotatingWords[currentWord].toLowerCase()}.jpg`;

  const rotatingComponent = () => (
    <div className={cx("column", styles.rotating)}>
      <h2>
        <RotatingTextAnimation
          rotatingWords={rotatingWords}
          currentWord={currentWord}
          setCurrentWord={setCurrentWord}
        />
      </h2>
      <figure className={styles.rotating__underline}>
        <img src={CurveIcon} alt="Curve Icon" />
      </figure>
    </div>
  );

  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywordList}
        imageUrl={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        pageUrl={RouteLinks.whoAmI}
      />
      <main
        className={cx("column", styles.mainContainer)}
        data-test-e2e="whoami-container"
      >
        {props.children}
        <section className={styles.left}>
          <img src={imageUrl} alt="Priyanka" />
          <div className={styles.left__overlay} />
        </section>
        <div className={styles.rotating__mobile}>
          {!rotatingTextResponsiveness && rotatingComponent()}
        </div>
        <section
          className={styles.right}
          style={{
            marginTop: rotatingTextResponsiveness
              ? `${props.headerHeight}px`
              : 0,
          }}
        >
          <div className={cx("column", styles.container)}>
            <div className={cx("column", styles.whoAmI)}>
              <div className={styles.whoAmI__title}>
                Who Am I?
                <p>I'm Priyanka</p>
              </div>
              <h1 className={cx("p1", styles.whoAmI__desc)}>
                Food, Wedding and Portrait Photographer
              </h1>
            </div>
            <div className={cx("column", styles.contact)}>
              <p className={cx("p1", styles.contact__desc)}>
                I’ll never stop experiencing and exploring absolutely everything
                the world has to offer me.
              </p>
              <LinkButton route={RouteLinks.contact} dataTestE2E="contact-link">
                Contact me
              </LinkButton>
            </div>
            <div className={styles.rotating__desktop}>
              {rotatingTextResponsiveness && rotatingComponent()}
            </div>
            <div className={styles.container__bottom}>
              <AsteriskIcon />
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default WhoAmI;
