import React from "react";
import WhoAmI1 from "../components/v1/pages/whoami";
import WhoAmI2 from "../components/v2/pages/whoami";

const WhoAmI = () => {
  if (process.env.GATSBY_VERSION === "v1") {
    return <WhoAmI1 />;
  } else {
    return <WhoAmI2 />;
  }
};

export default WhoAmI;
