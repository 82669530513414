export enum S3Directory {
  LANDING_PAGE_IMAGES = "landing_page_images",
  LANDING_PAGE_IMAGES_MOBILE = "landing_page_images_mobile",
  WHOAMI_PAGE_IMAGES = "whoami_page_images",
  TESTIMONIAL_IMAGES = "testimonial_images",

  // Portfolio Cover Mobile Directories
  PORTFOLIO_COVER_MOBILE_WEDDING = "portfolio_cover_images_mobile/wedding",
  PORTFOLIO_COVER_MOBILE_ENGAGEMENT = "portfolio_cover_images_mobile/engagement",
  PORTFOLIO_COVER_MOBILE_NEWBORN = "portfolio_cover_images_mobile/newborn",
  PORTFOLIO_COVER_MOBILE_MATERNITY = "portfolio_cover_images_mobile/maternity",
  PORTFOLIO_COVER_MOBILE_PORTRAIT = "portfolio_cover_images_mobile/portrait",
  PORTFOLIO_COVER_MOBILE_FAMILY = "portfolio_cover_images_mobile/family",
  PORTFOLIO_COVER_MOBILE_FOOD = "portfolio_cover_images_mobile/food",
  PORTFOLIO_COVER_MOBILE_EVENTS = "portfolio_cover_images_mobile/events",
  PORTFOLIO_COVER_MOBILE_TRAVEL = "portfolio_cover_images_mobile/travel",

  // Portfolio Cover Desktop Directories
  PORTFOLIO_COVER_WEDDING = "portfolio_cover_images/wedding",
  PORTFOLIO_COVER_ENGAGEMENT = "portfolio_cover_images/engagement",
  PORTFOLIO_COVER_NEWBORN = "portfolio_cover_images/newborn",
  PORTFOLIO_COVER_MATERNITY = "portfolio_cover_images/maternity",
  PORTFOLIO_COVER_PORTRAIT = "portfolio_cover_images/portrait",
  PORTFOLIO_COVER_FAMILY = "portfolio_cover_images/family",
  PORTFOLIO_COVER_FOOD = "portfolio_cover_images/food",
  PORTFOLIO_COVER_EVENTS = "portfolio_cover_images/events",
  PORTFOLIO_COVER_TRAVEL = "portfolio_cover_images/travel",

  // Portfolio Directories
  PORTFOLIO_WEDDING = "portfolio_images/wedding",
  PORTFOLIO_ENGAGEMENT = "portfolio_images/engagement",
  PORTFOLIO_NEWBORN = "portfolio_images/newborn",
  PORTFOLIO_MATERNITY = "portfolio_images/maternity",
  PORTFOLIO_PORTRAIT = "portfolio_images/portrait",
  PORTFOLIO_FAMILY = "portfolio_images/family",
  PORTFOLIO_FOOD = "portfolio_images/food",
  PORTFOLIO_EVENTS = "portfolio_images/events",
  PORTFOLIO_TRAVEL = "portfolio_images/travel",

  //Package Images
  PACKAGE_IMAGES = "package_images",
  PACKAGE_IMAGES_THUMB = "package_images_thumb",
  PACKAGE_IMAGES_MOBILE = "package_images_mobile",
  PACKAGE_IMAGES_MOBILE_THUMB = "package_images_mobile_thumb",
}
