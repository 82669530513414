import React, { useEffect, useState } from "react";
import "./RotatingTextAnimation.scss";
import { TEXT_TRANSITION_DURATION } from "../../../utils/constants";
import cx from "classnames";

interface Props {
  rotatingWords: string[];
  currentWord: number;
  setCurrentWord: Function;
  className?: string;
}

const RotatingTextAnimation: React.FC<Props> = (props: Props) => {
  const [wordArray, setWordArray] = useState<any>([]);
  const [selectedElementWidth, setSelectedElementWidth] = useState(90);
  const { currentWord, setCurrentWord } = props;

  useEffect(() => {
    const element = document.getElementById(
      `${props.rotatingWords[currentWord]}${currentWord}`
    );
    setSelectedElementWidth(element!.offsetWidth);
  }, [currentWord]);

  const splitLetters = (word: any) => {
    const content = word.innerHTML;
    word.innerHTML = "";
    const letters: any = [];
    for (let i = 0; i < content.length; i++) {
      const letter = document.createElement("span");
      letter.className = "letter";
      letter.innerHTML = content.charAt(i);
      word.appendChild(letter);
      letters.push(letter);
    }

    return letters;
  };

  function changeWord(words: any) {
    if (words == undefined) {
      return;
    }
    const cw = wordArray[currentWord];
    const nw =
      currentWord == words.length - 1
        ? wordArray[0]
        : wordArray[currentWord + 1];
    for (let i = 0; i < cw.length; i++) {
      animateLetterOut(cw, i);
    }

    for (let i = 0; i < nw.length; i++) {
      nw[i].className = "letter behind";
      nw[0].parentElement.style.opacity = 1;
      animateLetterIn(nw, i);
    }

    setCurrentWord(currentWord == wordArray.length - 1 ? 0 : currentWord + 1);
  }

  function animateLetterOut(cw: any, i: number) {
    setTimeout(() => {
      cw[i].className = "letter out";
    }, i);
  }

  function animateLetterIn(nw: any, i: number) {
    setTimeout(() => {
      nw[i].className = "letter in";
    }, 340 + i);
  }

  useEffect(() => {
    //Good - Animation
    const words = document.getElementsByClassName("word");

    // @ts-ignore
    words[currentWord].style.opacity = 1;
    const array: any = [];
    for (let i = 0; i < words.length; i++) {
      const letters = splitLetters(words[i]);
      array.push(letters);
    }
    setWordArray(array);
  }, []);

  useEffect(() => {
    const words = document.getElementsByClassName("word");

    const timer = setTimeout(() => {
      changeWord(words);
    }, TEXT_TRANSITION_DURATION);

    return () => {
      clearTimeout(timer);
    };
  }, [currentWord, wordArray]);

  return (
    <div
      className={cx("RotatingTextAnimation", props.className ?? "")}
      style={{ width: `${selectedElementWidth}px` }}
    >
      {props.rotatingWords.map((el, index) => (
        <span key={el + index} id={el + index} className="word">
          {el}
        </span>
      ))}
    </div>
  );
};

export default RotatingTextAnimation;
